import apiCustomEdit from "@/core/services/ApiCustomEdit";


const CATEGORY_ROUTE = "/categories";

export const getlistEditCategories = async (payload?) => {
    return await apiCustomEdit.query(CATEGORY_ROUTE, {
        params: payload,
    });
};

export const getsingleEditCategories = async (id) => {
    return await apiCustomEdit.get(`${CATEGORY_ROUTE}/${id}`, );
};

export const createEditCategories = async (payload?) => {
    return await apiCustomEdit.post(CATEGORY_ROUTE,  payload);
};

export const updateEditCategories = async (payload , id) => {
    return await apiCustomEdit.put(`${CATEGORY_ROUTE}/${id}`,  payload);
};

export const fetchStoreCategories = async (payload) => {
    return await apiCustomEdit.query(CATEGORY_ROUTE, {
        params: payload,
    });
};

export const singleDeleteStoreCategory = async (id) => {
    return await apiCustomEdit.delete(`${CATEGORY_ROUTE}/${id}`);
};


