import { __awaiter, __generator } from "tslib";
import { defineComponent, ref } from "vue";
import { storeUploadImaege } from "@/api/edit/ImageApi";
import useNotyf from "@/composable/useNotyf";
export default defineComponent({
    name: "BaseImageUploaderSingle",
    props: {
        modelValue: {
            type: String,
            default: "",
        },
        imagePlaceHolder: {
            type: String,
            default: "/default-placeholder.png" // Set default placeholder image
        }
    },
    setup: function (props, _a) {
        var _this = this;
        var emit = _a.emit;
        var notification = useNotyf();
        var fileInput = ref(null);
        var triggerFileInput = function () {
            var _a;
            (_a = fileInput.value) === null || _a === void 0 ? void 0 : _a.click();
        };
        var changeAvatar = function (e) {
            var target = e.target;
            var file = target.files ? target.files[0] : null;
            if (!file)
                return;
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () { return __awaiter(_this, void 0, void 0, function () {
                var blob, formData, res, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, fetch(reader.result).then(function (res) { return res.blob(); })];
                        case 1:
                            blob = _a.sent();
                            formData = new FormData();
                            formData.append("file", blob, file.name);
                            _a.label = 2;
                        case 2:
                            _a.trys.push([2, 4, , 5]);
                            return [4 /*yield*/, storeUploadImaege(formData)];
                        case 3:
                            res = _a.sent();
                            emit("update:modelValue", res.data.file_page);
                            notification.success("Image uploaded successfully!");
                            return [3 /*break*/, 5];
                        case 4:
                            error_1 = _a.sent();
                            notification.error("Failed to upload image.");
                            return [3 /*break*/, 5];
                        case 5: return [2 /*return*/];
                    }
                });
            }); };
            reader.onerror = function () {
                notification.error("Error reading file.");
            };
        };
        return {
            changeAvatar: changeAvatar,
            triggerFileInput: triggerFileInput,
            fileInput: fileInput,
        };
    },
});
