import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60ecca3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-check form-switch" }

import { ref, watch } from 'vue';


export default {
  props: {
  status: Boolean,
},
  emits: ['toggle'],
  setup(__props, { emit }) {

const props = __props



const isActive = ref(props.status);




watch(() => props.status, (newStatus) => {
  isActive.value = newStatus;
});

const toggle = () => {
  emit('toggle', isActive.value);
};

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: "form-check-input custom-switch",
      type: "checkbox",
      role: "switch",
      id: "flexSwitchCheckDefault",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((isActive).value = $event)),
      onChange: toggle
    }, null, 544), [
      [_vModelCheckbox, isActive.value]
    ])
  ]))
}
}

}