import apiCustomEdit from "@/core/services/ApiCustomEdit";


export const getEditProductList = async (payload) => {
    return await apiCustomEdit.query(`/products`, {
        params: payload,
    });
};

export const getsingleEditProduct = async (id) => {
    return await apiCustomEdit.get(`/products/${id}`);
};

export const createEditProduct = async (payload) => {
    return await apiCustomEdit.post(`/products`,  payload);
};


export const updateEditProduct = async (payload , id) => {
    return await apiCustomEdit.put(`/products/${id}`,  payload);
};

export const changeProdcutStatus = async (payload , id) => {
    return await apiCustomEdit.post(`/products/${id}/change-status`,  payload);
};

export const singleDeleteEditProduct = async (id) => {
    return await apiCustomEdit.delete(`/products/${id}`);
};

export const multipleDeleteEditCustomer = async (ids) => {
    return await apiCustomEdit.post(`/products/multi-delete`,  ids);
};

