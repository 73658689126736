import { __awaiter, __generator } from "tslib";
import { computed, defineComponent, ref, watch } from "vue";
import { wishlist, removeWishlist } from "@/api/WishlistApi";
import useNotyf from "@/composable/useNotyf";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { Modal } from "bootstrap";
import { audioPlayClick, getCartList, translate } from "@/core/helpers/custom";
import { addProductToCart } from "@/api/CartApi";
import { useI18n } from "vue-i18n/index";
import ProductFormModal from "@/components/modals/forms/ProductFormModal.vue";
import BaseFlex from "@/components/BaseColFlex.vue";
import BaseToggle from "@/components/BaseToggle.vue";
import { changeProdcutStatus, singleDeleteEditProduct } from "@/api/edit/productApi";
import Swal from 'sweetalert2';
export default defineComponent({
    name: "product-card-edit",
    components: { BaseToggle: BaseToggle, BaseFlex: BaseFlex, ProductFormModal: ProductFormModal, },
    props: {
        product: { type: Object },
        wishlist: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update"],
    setup: function (props, _a) {
        var _this = this;
        var _b;
        var emit = _a.emit;
        var notification = useNotyf();
        var isWishlist = ref(props.wishlist);
        var router = useRouter();
        var store = useStore();
        // const productDetail = ref<IProduct>();
        var productId = ref(0);
        var loading = ref(false);
        var addToCartItem = ref({});
        var t = useI18n().t;
        var isModalOpen = ref(false);
        var isImageLoad = ref(false);
        var currentRouteName = computed(function () {
            return router.currentRoute.value.name;
        });
        var route = useRoute();
        var isEditing = ref(false);
        var status = ref((_b = props.product) === null || _b === void 0 ? void 0 : _b.status);
        var wishlistAction = function (product_id) { return __awaiter(_this, void 0, void 0, function () {
            var myModal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!store.getters.isUserAuthenticated) return [3 /*break*/, 1];
                        myModal = new Modal(document.getElementById("kt_modal_login"), {
                            keyboard: false,
                        });
                        myModal.show();
                        return [3 /*break*/, 5];
                    case 1:
                        if (!isWishlist.value) return [3 /*break*/, 3];
                        return [4 /*yield*/, removeWishlist({
                                product_id: product_id,
                            })
                                .then(function () {
                                audioPlayClick("Mouse");
                                notification.success(t("This item has been removed from your wishlist."));
                                isWishlist.value = false;
                            })
                                .catch(function () {
                                notification.error(translate("Failed attempt to removed from wishlist!"));
                            })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, wishlist({
                            product_id: product_id,
                        })
                            .then(function () {
                            audioPlayClick("Mouse");
                            notification.success(t("This item has been added to your wishlist."));
                            isWishlist.value = true;
                        })
                            .catch(function () {
                            notification.error(translate("Failed attempt to add to wishlist!"));
                        })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        var handleProductUpdate = function (updatedProduct) {
            if (updatedProduct && isModalOpen.value === true) {
                emit("update");
            }
        };
        var openModal = function (type) {
            if (isEditing.value) {
                isModalOpen.value = true;
            }
        };
        var closeModal = function () {
            isModalOpen.value = false;
        };
        var addToCart = function (value) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        audioPlayClick("AddToCart");
                        addToCartItem.value = {
                            product_id: value,
                            quantity: 1,
                            options: [],
                        };
                        if (!(((_a = props.product) === null || _a === void 0 ? void 0 : _a.out_of_stock_status) === "In Stock")) return [3 /*break*/, 2];
                        return [4 /*yield*/, addProductToCart(addToCartItem.value)
                                .then(function (res) {
                                notification.success(
                                // res.data.message ?? "Add to cart successfully"
                                t("Add to cart successfully")
                                // "Success"
                                );
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _b.sent();
                        getCartList();
                        return [3 /*break*/, 3];
                    case 2:
                        // notification.error("Product out of stock!");
                        notification.error(translate("Out of stock!"));
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var handleToggle = function (state) {
            handleChangeProductStatus({ status: state });
        };
        var handleChangeProductStatus = function (payload) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, changeProdcutStatus(payload, (_a = props.product) === null || _a === void 0 ? void 0 : _a.id)
                            .then(function (res) {
                            notification.success(t(res.data.message));
                        })
                            .catch(function () {
                            "_";
                        })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var deleteProduct = function () { return __awaiter(_this, void 0, void 0, function () {
            var result;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Swal.fire({
                            title: "Are you sure?",
                            text: "You won't be able to revert this!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#00AF27",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, delete it!"
                        })];
                    case 1:
                        result = _b.sent();
                        if (!result.isConfirmed) return [3 /*break*/, 3];
                        return [4 /*yield*/, singleDeleteEditProduct((_a = props.product) === null || _a === void 0 ? void 0 : _a.id)
                                .then(function (res) {
                                Swal.fire({
                                    title: "Deleted!",
                                    text: res.data.message,
                                    icon: "success",
                                    confirmButtonColor: "#00AF27"
                                });
                                emit("update");
                            })
                                .catch(function () {
                                Swal.fire({
                                    title: "Error!",
                                    text: "Failed to delete the product.",
                                    icon: "error",
                                    confirmButtonColor: "#d33"
                                });
                            })];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        watch(function () { return route.query.edit; }, function (newValue) {
            isEditing.value = newValue === "true"; // Convert to boolean
        }, { immediate: true } // Runs initially when the component is mounted
        );
        return {
            wishlistAction: wishlistAction,
            isWishlist: isWishlist,
            currentRouteName: currentRouteName,
            store: store,
            productId: productId,
            loading: loading,
            addToCart: addToCart,
            isImageLoad: isImageLoad,
            isEditing: isEditing,
            isModalOpen: isModalOpen,
            openModal: openModal,
            closeModal: closeModal,
            handleProductUpdate: handleProductUpdate,
            status: status,
            handleToggle: handleToggle,
            deleteProduct: deleteProduct,
        };
    },
});
