import { __assign, __awaiter, __generator, __spreadArrays } from "tslib";
import { ref, onMounted, } from "vue";
import * as yup from "yup";
import { Form } from "vee-validate";
import BaseField from "@/components/BaseField.vue";
import BaseCol from "@/components/BaseCol.vue";
import BaseRow from "@/components/BaseRow.vue";
import { getlistEditCategories } from "@/api/edit/CategoryApi";
import BaseImageUploadSingle from "@/components/BaseImageUploadSingle.vue";
import { createEditProduct, getsingleEditProduct, updateEditProduct } from "@/api/edit/productApi";
import useNotyf from "@/composable/useNotyf";
import { useI18n } from "vue-i18n";
import { translate } from "@/core/helpers/custom";
var validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    categories: yup.array().min(1, "Category is required"),
    quantity: yup.number().required("Quantity is required").min(0, "Quantity must be greater than zero"),
    price: yup.number().required("Price is required").min(0, "Price cannot be negative"),
});
export default {
    name: "product-form-modal",
    components: {
        BaseImageUploadSingle: BaseImageUploadSingle,
        BaseRow: BaseRow,
        BaseCol: BaseCol,
        BaseField: BaseField,
        Form: Form,
    },
    props: {
        id: {
            type: Number,
        }
    },
    setup: function (props, _a) {
        var _this = this;
        var emit = _a.emit;
        // Form Data
        var form = ref({
            name: "",
            categories: [],
            images: [
                {
                    image_url: "",
                    path: "",
                    name: "",
                    main: 0,
                }
            ],
            price: 0,
            quantity: 0,
            date_available: "",
            minimum_order: 1,
            subtract_stock: "yes",
            out_of_stock_status: "in_stock",
            sort_order: 0,
            is_new: false,
            is_pin: false,
            brand: null,
            manufacture: null,
            status: true,
        });
        var i18n = useI18n();
        var notification = useNotyf();
        var loading = ref(false);
        var isCategoryOptionsLoading = ref(false);
        var categoryData = ref([]);
        var editmode = ref(false);
        // Submit Function
        var submitModal = function (value) { return __awaiter(_this, void 0, void 0, function () {
            var formCopy, response, error_1, formCopy, response, error_2;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        loading.value = true;
                        if (form.value.brand && typeof form.value.brand === "object") {
                            form.value.brand = form.value.brand.id ? { id: form.value.brand.id, name: form.value.brand.name } : null;
                        }
                        if (form.value.manufacture && typeof form.value.manufacture === "object") {
                            form.value.manufacture = form.value.manufacture.id ? { id: form.value.manufacture.id, name: form.value.manufacture.name } : null;
                        }
                        if (form.value.images[0].image_url !== "") {
                            form.value.images[0].main = 0;
                            form.value.images[0].name = form.value.images[0].image_url;
                            form.value.images[0].path = form.value.images[0].image_url;
                        }
                        if (!editmode.value) return [3 /*break*/, 7];
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 4, 5, 6]);
                        formCopy = __assign(__assign({}, form.value), { images: __spreadArrays(form.value.images) });
                        if (((_a = formCopy.images[0]) === null || _a === void 0 ? void 0 : _a.image_url) === "") {
                            formCopy.images = [];
                        }
                        return [4 /*yield*/, updateEditProduct(formCopy, props.id)];
                    case 2:
                        response = _c.sent();
                        categoryData.value = response.data.data;
                        notification.success(translate(response.data.message));
                        return [4 /*yield*/, handleFetchCategories()];
                    case 3:
                        _c.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        error_1 = _c.sent();
                        console.log(error_1);
                        notification.error(translate(error_1.message));
                        return [3 /*break*/, 6];
                    case 5:
                        emit("productUpdated", form.value);
                        return [7 /*endfinally*/];
                    case 6: return [3 /*break*/, 11];
                    case 7:
                        _c.trys.push([7, 9, 10, 11]);
                        formCopy = __assign(__assign({}, form.value), { images: __spreadArrays(form.value.images) });
                        if (((_b = formCopy.images[0]) === null || _b === void 0 ? void 0 : _b.image_url) === "") {
                            formCopy.images = [];
                        }
                        formCopy.date_available = new Date().toISOString().split("T")[0];
                        return [4 /*yield*/, createEditProduct(formCopy)];
                    case 8:
                        response = _c.sent();
                        categoryData.value = response.data.data;
                        notification.success(translate(response.data.message));
                        return [3 /*break*/, 11];
                    case 9:
                        error_2 = _c.sent();
                        console.log(error_2);
                        notification.error(translate(error_2.message));
                        return [3 /*break*/, 11];
                    case 10:
                        loading.value = false;
                        return [7 /*endfinally*/];
                    case 11:
                        if (value) {
                            closeModal();
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        var closeModal = function () {
            emit("closeModal");
        };
        var handleFetchCategories = function () { return __awaiter(_this, void 0, void 0, function () {
            var response, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isCategoryOptionsLoading.value = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, getlistEditCategories({ search: "", limit: 100 })];
                    case 2:
                        response = _a.sent();
                        categoryData.value = response.data.data;
                        return [3 /*break*/, 5];
                    case 3:
                        error_3 = _a.sent();
                        console.log(error_3);
                        return [3 /*break*/, 5];
                    case 4:
                        isCategoryOptionsLoading.value = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        var handleFetchSingleProduct = function () { return __awaiter(_this, void 0, void 0, function () {
            var response, error_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, getsingleEditProduct(props.id)];
                    case 2:
                        response = _a.sent();
                        form.value = response.data.data;
                        if (form.value.categories) {
                            form.value.categories = form.value.categories.map(function (cat) { return cat.id; });
                        }
                        if (!form.value.images || !Array.isArray(form.value.images) || form.value.images.length === 0) {
                            form.value.images = [{
                                    image_url: "",
                                    path: "",
                                    name: "",
                                    main: 0,
                                }];
                        }
                        else {
                            form.value.images = form.value.images.map(function (img) { return (__assign(__assign({}, img), { image_url: img.image_url || "" })); });
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_4 = _a.sent();
                        console.log(error_4);
                        return [3 /*break*/, 5];
                    case 4:
                        loading.value = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        onMounted(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, handleFetchCategories()];
                    case 1:
                        _a.sent();
                        if (!props.id) return [3 /*break*/, 3];
                        editmode.value = true;
                        return [4 /*yield*/, handleFetchSingleProduct()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        editmode.value = false;
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); });
        return {
            form: form,
            submitModal: submitModal,
            validationSchema: validationSchema,
            handleFetchCategories: handleFetchCategories,
            isCategoryOptionsLoading: isCategoryOptionsLoading,
            categoryData: categoryData,
            closeModal: closeModal,
            editmode: editmode,
            loading: loading
        };
    },
};
