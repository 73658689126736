import { __awaiter, __generator, __spreadArrays } from "tslib";
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { debounce } from "lodash";
import { getEditProductList } from "@/api/edit/productApi";
import baseProductCardEdit from "@/components/BaseProductCardEdit.vue";
export default defineComponent({
    name: "home-edit",
    components: {
        baseProductCardEdit: baseProductCardEdit
    },
    setup: function () {
        var _this = this;
        var products = ref([]);
        var loading = ref(false);
        var product = ref();
        var fullProductRoute = ref("");
        var pagination = ref();
        var store = useStore();
        var route = useRoute();
        var width = ref(0);
        var loadMoreLoading = ref({
            load: false,
        });
        var currentInfinitePage = ref({
            page: 1,
            isEnd: false,
            totalPage: 0,
            search: "",
        });
        var getListOfProducts = function (payload) { return __awaiter(_this, void 0, void 0, function () {
            var res, error_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        loading.value = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, getEditProductList(payload)];
                    case 2:
                        res = _b.sent();
                        if ((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) {
                            // Reset the products array before assigning new values
                            products.value = __spreadArrays(res.data.data);
                            pagination.value = {
                                page: res.data.meta.current_page,
                                total: res.data.meta.total,
                                rowsPerPage: res.data.meta.per_page,
                                totalPage: res.data.meta.last_page,
                            };
                            currentInfinitePage.value.totalPage = res.data.meta.last_page;
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _b.sent();
                        console.error("Error fetching products:", error_1);
                        return [3 /*break*/, 4];
                    case 4:
                        loading.value = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var currentPageChange = function (val) {
            getListOfProducts({
                page: val,
            });
        };
        // watch(
        //   () => store.getters.isUserAuthenticated,
        //   function () {
        //     getListOfProducts({});
        //   }
        // );
        watch(function () { return route.query; }, function (query) {
            currentInfinitePage.value = {
                page: 1,
                isEnd: false,
                totalPage: 0,
                search: query === null || query === void 0 ? void 0 : query.search,
            };
            getListOfProducts({ name: query === null || query === void 0 ? void 0 : query.search, category: query === null || query === void 0 ? void 0 : query.category });
        });
        var handleMouseEnter = function () {
            getInfiniteProductList(currentInfinitePage, products);
        };
        var handleScroll = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var element, scrollWidth, fixedHeight, isBottom;
            return __generator(this, function (_a) {
                element = e.currentTarget;
                scrollWidth = parseInt(element.scrollHeight) - parseInt(element.scrollTop);
                fixedHeight = parseInt(element.clientHeight);
                isBottom = scrollWidth >= fixedHeight - 1 && scrollWidth <= fixedHeight + 1;
                if (isBottom) {
                    if (!currentInfinitePage.value.isEnd) {
                        getInfiniteProductList(currentInfinitePage, products);
                    }
                }
                return [2 /*return*/];
            });
        }); };
        var getInfiniteProductList = debounce(function (currentInfinitePage, products) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        loadMoreLoading.value.load = true;
                        return [4 /*yield*/, getEditProductList({
                                page: ++currentInfinitePage.value.page,
                                keyword: (_a = currentInfinitePage.value) === null || _a === void 0 ? void 0 : _a.search,
                            })
                                .then(function (res) {
                                var _a;
                                (_a = products.value).push.apply(_a, res.data.data);
                                var lastPage = res.data.meta.last_page;
                                if (currentInfinitePage.value.page >= lastPage) {
                                    currentInfinitePage.value.isEnd = true;
                                    --currentInfinitePage.value.page;
                                }
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _b.sent();
                        loadMoreLoading.value.load = false;
                        return [2 /*return*/];
                }
            });
        }); }, 250);
        onMounted(function () {
            var _a, _b;
            getListOfProducts({ page: 1, keyword: (_a = route.query) === null || _a === void 0 ? void 0 : _a.search });
            setCurrentPageBreadcrumbs((_b = route.params.from) !== null && _b !== void 0 ? _b : "edit", [
                "All Product",
            ]);
        });
        return {
            products: products,
            loading: loading,
            product: product,
            fullProductRoute: fullProductRoute,
            pagination: pagination,
            currentPageChange: currentPageChange,
            store: store,
            handleMouseEnter: handleMouseEnter,
            loadMoreLoading: loadMoreLoading,
            currentInfinitePage: currentInfinitePage,
            width: width,
            getInfiniteProductList: getInfiniteProductList,
            handleScroll: handleScroll,
            getListOfProducts: getListOfProducts,
            route: route
        };
    },
});
