import apiCustomEdit from "@/core/services/ApiCustomEdit";
import store from "@/store";

export const storeUploadImaege = async (payload) => {
    const store_id = store.getters.getStoreData?.id; // Ensure store_id exists

    if (!store_id) {
        console.error("Store ID not found");
        return Promise.reject(new Error("Store ID not available"));
    }

    return await apiCustomEdit.post(`/file/upload/${store_id}`, payload);
};